html[data-theme='payroller'] {
  --primary-900: #1c4e4e;
  --primary-800: #1f6e6c;
  --primary-700: #05a09b;
  --primary-600: #2cb2ad;
  --primary-500: #53bbb7;
  --primary-400: #7dcdca;
  --primary-300: #a6dddd;
  --primary-200: #c5e7e6;
  --primary-100: #e3f7f7;
  --primary-50: #eefcfb;
  --font-sans: Inter;
}

html[data-theme='people'] {
  --primary-900: #233876;
  --primary-800: #1e429f;
  --primary-700: #1a56db;
  --primary-600: #1c64f2;
  --primary-500: #3f83f8;
  --primary-400: #76a9fa;
  --primary-300: #a4cafe;
  --primary-200: #c3ddfd;
  --primary-100: #e1effe;
  --primary-50: #ebf5ff;
  --font-sans: Inter;
}

*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.label-subtitle {
  font-size: 18px;
  font-weight: 500;
  color: #040607;
  font-family: Poppins, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  line-height: 1.4em;
}

.label-bold {
  font-size: 14px;
  font-weight: 600;
  color: #040607;
  font-family: Poppins, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  line-height: 1.4em;
}

.label-paragraph {
  font-size: 14px;
  color: #040607;
  font-family: Poppins, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  line-height: 1.4em;
}

.label-link {
  font-family: Poppins, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  color: #459998;
  cursor: pointer;
  line-height: 1.4em;
  font-size: 14px;
  text-decoration-line: underline;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.pointer-events-none {
  pointer-events: none;
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.\!relative {
  position: relative !important;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0px;
}

.inset-x-1\/2 {
  left: 50%;
  right: 50%;
}

.inset-y-0 {
  top: 0px;
  bottom: 0px;
}

.\!-left-\[6px\] {
  left: -6px !important;
}

.\!bottom-20 {
  bottom: 5rem !important;
}

.-top-0 {
  top: -0px;
}

.-top-1 {
  top: -0.25rem;
}

.-top-2 {
  top: -0.5rem;
}

.bottom-0 {
  bottom: 0px;
}

.bottom-1 {
  bottom: 0.25rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.left-0 {
  left: 0px;
}

.left-1\/2 {
  left: 50%;
}

.right-0 {
  right: 0px;
}

.right-2 {
  right: 0.5rem;
}

.right-20 {
  right: 5rem;
}

.right-3 {
  right: 0.75rem;
}

.right-4 {
  right: 1rem;
}

.right-5 {
  right: 1.25rem;
}

.right-6 {
  right: 1.5rem;
}

.right-\[20px\] {
  right: 20px;
}

.top-0 {
  top: 0px;
}

.top-2 {
  top: 0.5rem;
}

.top-3 {
  top: 0.75rem;
}

.top-4 {
  top: 1rem;
}

.top-6 {
  top: 1.5rem;
}

.top-\[104px\] {
  top: 104px;
}

.top-\[10px\] {
  top: 10px;
}

.top-\[50\%\] {
  top: 50%;
}

.\!z-50 {
  z-index: 50 !important;
}

.\!z-popover {
  z-index: 99999999 !important;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[1\] {
  z-index: 1;
}

.z-alert {
  z-index: 105000001;
}

.z-modal {
  z-index: 105000001;
}

.z-popover {
  z-index: 99999999;
}

.z-tsrMobile {
  z-index: 4;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-start-2 {
  grid-column-start: 2;
}

.float-right {
  float: right;
}

.\!m-0 {
  margin: 0px !important;
}

.\!m-1 {
  margin: 0.25rem !important;
}

.\!m-2 {
  margin: 0.5rem !important;
}

.\!m-4 {
  margin: 1rem !important;
}

.\!m-auto {
  margin: auto !important;
}

.-m-2 {
  margin: -0.5rem;
}

.m-0 {
  margin: 0px;
}

.m-1 {
  margin: 0.25rem;
}

.m-1\.5 {
  margin: 10px;
}

.m-3 {
  margin: 0.75rem;
}

.m-4 {
  margin: 1rem;
}

.m-auto {
  margin: auto;
}

.\!mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.\!my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.\!my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.\!my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.-my-modalTopSpace {
  margin-top: -28px;
  margin-bottom: -28px;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-\[1\.5rem\] {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-\[16px\] {
  margin-left: 16px;
  margin-right: 16px;
}

.mx-\[20px\] {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-\[40px\] {
  margin-left: 40px;
  margin-right: 40px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-\[50px\] {
  margin-top: 50px;
  margin-bottom: 50px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.\!-ml-\[135px\] {
  margin-left: -135px !important;
}

.\!-ml-\[250px\] {
  margin-left: -250px !important;
}

.\!-mt-2 {
  margin-top: -0.5rem !important;
}

.\!-mt-\[50px\] {
  margin-top: -50px !important;
}

.\!-mt-\[90px\] {
  margin-top: -90px !important;
}

.\!mb-4 {
  margin-bottom: 1rem !important;
}

.\!mb-5 {
  margin-bottom: 1.25rem !important;
}

.\!mb-6 {
  margin-bottom: 1.5rem !important;
}

.\!ml-4 {
  margin-left: 1rem !important;
}

.\!mt-1 {
  margin-top: 0.25rem !important;
}

.\!mt-2 {
  margin-top: 0.5rem !important;
}

.\!mt-3 {
  margin-top: 0.75rem !important;
}

.\!mt-4 {
  margin-top: 1rem !important;
}

.-ml-20 {
  margin-left: -5rem;
}

.-mr-1 {
  margin-right: -0.25rem;
}

.-mt-modalTopSpace {
  margin-top: -28px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-0\.5 {
  margin-bottom: 2px;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-1\.5 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-2\.5 {
  margin-bottom: 0.625rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-64 {
  margin-bottom: 16rem;
}

.mb-\[16px\] {
  margin-bottom: 16px;
}

.mb-\[5px\] {
  margin-bottom: 5px;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-2\.5 {
  margin-top: 0.625rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[12px\] {
  margin-top: 12px;
}

.mt-\[20px\] {
  margin-top: 20px;
}

.mt-\[30px\] {
  margin-top: 30px;
}

.mt-\[32px\] {
  margin-top: 32px;
}

.mt-\[40px\] {
  margin-top: 40px;
}

.mt-\[69px\] {
  margin-top: 69px;
}

.mt-\[6px\] {
  margin-top: 6px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.\!h-10 {
  height: 2.5rem !important;
}

.\!h-20 {
  height: 5rem !important;
}

.\!h-3 {
  height: 0.75rem !important;
}

.\!h-4 {
  height: 1rem !important;
}

.\!h-\[20px\] {
  height: 20px !important;
}

.\!h-\[24px\] {
  height: 24px !important;
}

.\!h-full {
  height: 100% !important;
}

.\!h-screen {
  height: 100vh !important;
}

.h-0 {
  height: 0px;
}

.h-0\.5 {
  height: 2px;
}

.h-1 {
  height: 0.25rem;
}

.h-1\.5 {
  height: 10px;
}

.h-1\/2 {
  height: 50%;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: 0.5rem;
}

.h-2\.5 {
  height: 0.625rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-3 {
  height: 0.75rem;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-44 {
  height: 11rem;
}

.h-5 {
  height: 1.25rem;
}

.h-52 {
  height: 13rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-96 {
  height: 24rem;
}

.h-\[102px\] {
  height: 102px;
}

.h-\[108px\] {
  height: 108px;
}

.h-\[10px\] {
  height: 10px;
}

.h-\[116px\] {
  height: 116px;
}

.h-\[135px\] {
  height: 135px;
}

.h-\[150px\] {
  height: 150px;
}

.h-\[16px\] {
  height: 16px;
}

.h-\[180px\] {
  height: 180px;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[19px\] {
  height: 19px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[21px\] {
  height: 21px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[26px\] {
  height: 26px;
}

.h-\[28px\] {
  height: 28px;
}

.h-\[33px\] {
  height: 33px;
}

.h-\[34px\] {
  height: 34px;
}

.h-\[36px\] {
  height: 36px;
}

.h-\[37px\] {
  height: 37px;
}

.h-\[396px\] {
  height: 396px;
}

.h-\[432px\] {
  height: 432px;
}

.h-\[483px\] {
  height: 483px;
}

.h-\[48px\] {
  height: 48px;
}

.h-\[52px\] {
  height: 52px;
}

.h-\[53px\] {
  height: 53px;
}

.h-\[54px\] {
  height: 54px;
}

.h-\[56px\] {
  height: 56px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[61px\] {
  height: 61px;
}

.h-\[62px\] {
  height: 62px;
}

.h-\[68px\] {
  height: 68px;
}

.h-\[72px\] {
  height: 72px;
}

.h-\[75px\] {
  height: 75px;
}

.h-\[79px\] {
  height: 79px;
}

.h-\[81px\] {
  height: 81px;
}

.h-\[90vh\] {
  height: 90vh;
}

.h-\[92px\] {
  height: 92px;
}

.h-\[94px\] {
  height: 94px;
}

.h-\[95vh\] {
  height: 95vh;
}

.h-\[96px\] {
  height: 96px;
}

.h-\[96x\] {
  height: 96x;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-min {
  height: -moz-min-content;
  height: min-content;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.\!max-h-full {
  max-height: 100% !important;
}

.max-h-20 {
  max-height: 5rem;
}

.max-h-24 {
  max-height: 6rem;
}

.max-h-40 {
  max-height: 10rem;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-\[140px\] {
  max-height: 140px;
}

.max-h-\[192px\] {
  max-height: 192px;
}

.max-h-\[200px\] {
  max-height: 200px;
}

.max-h-\[250px\] {
  max-height: 250px;
}

.max-h-\[400px\] {
  max-height: 400px;
}

.max-h-\[72px\] {
  max-height: 72px;
}

.max-h-\[90vh\] {
  max-height: 90vh;
}

.max-h-\[924px\] {
  max-height: 924px;
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-\[19px\] {
  min-height: 19px;
}

.min-h-\[26px\] {
  min-height: 26px;
}

.min-h-\[400px\] {
  min-height: 400px;
}

.min-h-\[40px\] {
  min-height: 40px;
}

.min-h-\[45px\] {
  min-height: 45px;
}

.min-h-\[54px\] {
  min-height: 54px;
}

.min-h-\[96px\] {
  min-height: 96px;
}

.min-h-\[99px\] {
  min-height: 99px;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.\!w-24 {
  width: 6rem !important;
}

.\!w-3 {
  width: 0.75rem !important;
}

.\!w-4 {
  width: 1rem !important;
}

.\!w-40 {
  width: 10rem !important;
}

.\!w-72 {
  width: 18rem !important;
}

.\!w-\[20px\] {
  width: 20px !important;
}

.\!w-\[245px\] {
  width: 245px !important;
}

.\!w-\[24px\] {
  width: 24px !important;
}

.\!w-\[268px\] {
  width: 268px !important;
}

.\!w-\[350px\] {
  width: 350px !important;
}

.\!w-\[412px\] {
  width: 412px !important;
}

.\!w-\[416px\] {
  width: 416px !important;
}

.\!w-\[421px\] {
  width: 421px !important;
}

.\!w-\[448px\] {
  width: 448px !important;
}

.\!w-\[500px\] {
  width: 500px !important;
}

.\!w-\[796px\] {
  width: 796px !important;
}

.\!w-fit {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.\!w-full {
  width: 100% !important;
}

.\!w-screen {
  width: 100vw !important;
}

.w-0 {
  width: 0px;
}

.w-1 {
  width: 0.25rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-1\/4 {
  width: 25%;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: 0.75rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-96 {
  width: 24rem;
}

.w-\[10px\] {
  width: 10px;
}

.w-\[116px\] {
  width: 116px;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[1340px\] {
  width: 1340px;
}

.w-\[145px\] {
  width: 145px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[18px\] {
  width: 18px;
}

.w-\[1px\] {
  width: 1px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[220px\] {
  width: 220px;
}

.w-\[230px\] {
  width: 230px;
}

.w-\[248px\] {
  width: 248px;
}

.w-\[250px\] {
  width: 250px;
}

.w-\[290px\] {
  width: 290px;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[33px\] {
  width: 33px;
}

.w-\[343px\] {
  width: 343px;
}

.w-\[350px\] {
  width: 350px;
}

.w-\[352px\] {
  width: 352px;
}

.w-\[362px\] {
  width: 362px;
}

.w-\[364px\] {
  width: 364px;
}

.w-\[368px\] {
  width: 368px;
}

.w-\[38px\] {
  width: 38px;
}

.w-\[390px\] {
  width: 390px;
}

.w-\[400px\] {
  width: 400px;
}

.w-\[412px\] {
  width: 412px;
}

.w-\[416px\] {
  width: 416px;
}

.w-\[442px\] {
  width: 442px;
}

.w-\[450px\] {
  width: 450px;
}

.w-\[46px\] {
  width: 46px;
}

.w-\[470px\] {
  width: 470px;
}

.w-\[480px\] {
  width: 480px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[54px\] {
  width: 54px;
}

.w-\[610px\] {
  width: 610px;
}

.w-\[628px\] {
  width: 628px;
}

.w-\[700px\] {
  width: 700px;
}

.w-\[896px\] {
  width: 896px;
}

.w-\[calc\(100vw-250px\)\] {
  width: calc(100vw - 250px);
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-max {
  width: -moz-max-content;
  width: max-content;
}

.w-min {
  width: -moz-min-content;
  width: min-content;
}

.w-screen {
  width: 100vw;
}

.min-w-\[177px\] {
  min-width: 177px;
}

.min-w-\[240px\] {
  min-width: 240px;
}

.min-w-\[350px\] {
  min-width: 350px;
}

.min-w-\[920px\] {
  min-width: 920px;
}

.min-w-full {
  min-width: 100%;
}

.\!max-w-\[268px\] {
  max-width: 268px !important;
}

.\!max-w-\[421px\] {
  max-width: 421px !important;
}

.\!max-w-\[500px\] {
  max-width: 500px !important;
}

.\!max-w-\[90vw\] {
  max-width: 90vw !important;
}

.\!max-w-fit {
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}

.\!max-w-full {
  max-width: 100% !important;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-\[480px\] {
  max-width: 480px;
}

.max-w-\[788px\] {
  max-width: 788px;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-max {
  max-width: -moz-max-content;
  max-width: max-content;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.grow {
  flex-grow: 1;
}

.basis-0 {
  flex-basis: 0px;
}

.table-fixed {
  table-layout: fixed;
}

.border-collapse {
  border-collapse: collapse;
}

.origin-top-left {
  transform-origin: top left;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[calc\(100\%-28px\)\] {
  --tw-translate-y: calc(100% - 28px);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.\!cursor-not-allowed {
  cursor: not-allowed !important;
}

.\!cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.resize-y {
  resize: vertical;
}

.list-decimal {
  list-style-type: decimal;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.auto-cols-auto {
  grid-auto-columns: auto;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.\!flex-row {
  flex-direction: row !important;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.\!items-center {
  align-items: center !important;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-normal {
  justify-content: normal;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

.\!gap-0 {
  gap: 0px !important;
}

.gap-0 {
  gap: 0px;
}

.gap-0\.5 {
  gap: 2px;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-1\.5 {
  gap: 10px;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-2\.5 {
  gap: 0.625rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-3\.5 {
  gap: 14px;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-\[15px\] {
  gap: 15px;
}

.gap-\[18px\] {
  gap: 18px;
}

.gap-\[20px\] {
  gap: 20px;
}

.gap-\[5px\] {
  gap: 5px;
}

.gap-x-1 {
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem;
}

.gap-x-1\.5 {
  -moz-column-gap: 10px;
       column-gap: 10px;
}

.gap-x-14 {
  -moz-column-gap: 3.5rem;
       column-gap: 3.5rem;
}

.gap-x-2 {
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}

.gap-x-2\.5 {
  -moz-column-gap: 0.625rem;
       column-gap: 0.625rem;
}

.gap-x-3 {
  -moz-column-gap: 0.75rem;
       column-gap: 0.75rem;
}

.gap-x-4 {
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

.gap-x-5 {
  -moz-column-gap: 1.25rem;
       column-gap: 1.25rem;
}

.gap-y-1 {
  row-gap: 0.25rem;
}

.gap-y-1\.5 {
  row-gap: 10px;
}

.gap-y-2 {
  row-gap: 0.5rem;
}

.gap-y-2\.5 {
  row-gap: 0.625rem;
}

.gap-y-3 {
  row-gap: 0.75rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.overflow-auto {
  overflow: auto;
}

.\!overflow-hidden {
  overflow: hidden !important;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-nowrap {
  text-wrap: nowrap;
}

.text-balance {
  text-wrap: balance;
}

.break-words {
  overflow-wrap: break-word;
}

.\!rounded-lg {
  border-radius: 0.5rem !important;
}

.\!rounded-none {
  border-radius: 0px !important;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[34px\] {
  border-radius: 34px;
}

.rounded-\[4px\] {
  border-radius: 4px;
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-none {
  border-radius: 0px;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.\!rounded-l-lg {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.\!rounded-l-none {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.\!rounded-r-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.\!rounded-r-none {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-r-none {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}

.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}

.rounded-tl-xl {
  border-top-left-radius: 0.75rem;
}

.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}

.rounded-tr-xl {
  border-top-right-radius: 0.75rem;
}

.\!border {
  border-width: 1px !important;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0px;
}

.border-0\.5 {
  border-width: 0.5px;
}

.border-2 {
  border-width: 2px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-y-0 {
  border-top-width: 0px;
  border-bottom-width: 0px;
}

.border-y-0\.5 {
  border-top-width: 0.5px;
  border-bottom-width: 0.5px;
}

.\!border-b-0 {
  border-bottom-width: 0px !important;
}

.\!border-l-0 {
  border-left-width: 0px !important;
}

.\!border-r {
  border-right-width: 1px !important;
}

.\!border-r-0 {
  border-right-width: 0px !important;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0px;
}

.border-b-0\.5 {
  border-bottom-width: 0.5px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-l-0\.5 {
  border-left-width: 0.5px;
}

.border-r {
  border-right-width: 1px;
}

.border-r-0 {
  border-right-width: 0px;
}

.border-r-0\.5 {
  border-right-width: 0.5px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-t-\[1px\] {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.\!border-gray-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.\!border-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.\!border-grey200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.\!border-newPrimary700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(5 160 155 / var(--tw-border-opacity)) !important;
}

.\!border-primary-600 {
  border-color: var(--primary-600) !important;
}

.\!border-red-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(200 30 30 / var(--tw-border-opacity)) !important;
}

.border-\[\#E5E7EB\] {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-grayBorder {
  --tw-border-opacity: 1;
  border-color: rgb(198 198 198 / var(--tw-border-opacity));
}

.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgb(188 240 218 / var(--tw-border-opacity));
}

.border-grey200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-inputBorderGray {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-nGrayBorder {
  --tw-border-opacity: 1;
  border-color: rgb(240 240 241 / var(--tw-border-opacity));
}

.border-newPrimary600 {
  --tw-border-opacity: 1;
  border-color: rgb(44 178 173 / var(--tw-border-opacity));
}

.border-newPrimary800 {
  --tw-border-opacity: 1;
  border-color: rgb(31 110 108 / var(--tw-border-opacity));
}

.border-primary-500 {
  border-color: var(--primary-500);
}

.border-primary-600 {
  border-color: var(--primary-600);
}

.border-red {
  --tw-border-opacity: 1;
  border-color: rgb(242 84 91 / var(--tw-border-opacity));
}

.border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgb(251 213 213 / var(--tw-border-opacity));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(249 128 128 / var(--tw-border-opacity));
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: transparent;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgb(252 233 106 / var(--tw-border-opacity));
}

.border-y-grey200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(229 231 235 / var(--tw-border-opacity));
  border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-b-gray-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-b-grey200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-l-grey200 {
  --tw-border-opacity: 1;
  border-left-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-r-grey200 {
  --tw-border-opacity: 1;
  border-right-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-t-gray-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.\!bg-\[\#C81E1E\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(200 30 30 / var(--tw-bg-opacity)) !important;
}

.\!bg-gray-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}

.\!bg-inputBgGray {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}

.\!bg-newPrimary100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(233 248 251 / var(--tw-bg-opacity)) !important;
}

.\!bg-newPrimary700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(5 160 155 / var(--tw-bg-opacity)) !important;
}

.\!bg-primary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(77 170 169 / var(--tw-bg-opacity)) !important;
}

.\!bg-red-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(240 82 82 / var(--tw-bg-opacity)) !important;
}

.\!bg-transparent {
  background-color: transparent !important;
}

.\!bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.bg-\[\#1A56DB\] {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity));
}

.bg-\[\#1F6E6C\] {
  --tw-bg-opacity: 1;
  background-color: rgb(31 110 108 / var(--tw-bg-opacity));
}

.bg-\[\#DEF7EC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(222 247 236 / var(--tw-bg-opacity));
}

.bg-\[\#E1EFFE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(225 239 254 / var(--tw-bg-opacity));
}

.bg-\[\#E5EDFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 237 255 / var(--tw-bg-opacity));
}

.bg-\[\#EDEBFE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 235 254 / var(--tw-bg-opacity));
}

.bg-\[\#EF4444\] {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-\[\#F3F4F6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-\[\#FDE8E8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 232 232 / var(--tw-bg-opacity));
}

.bg-\[\#FDF6B2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 178 / var(--tw-bg-opacity));
}

.bg-alertSuccess {
  --tw-bg-opacity: 1;
  background-color: rgb(54 178 77 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(4 6 7 / var(--tw-bg-opacity));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(235 245 255 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(222 247 236 / var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(188 240 218 / var(--tw-bg-opacity));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 250 247 / var(--tw-bg-opacity));
}

.bg-green100 {
  --tw-bg-opacity: 1;
  background-color: rgb(222 247 236 / var(--tw-bg-opacity));
}

.bg-grey10 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 248 248 / var(--tw-bg-opacity));
}

.bg-grey100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-grey120 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 150 159 / var(--tw-bg-opacity));
}

.bg-grey20 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 240 241 / var(--tw-bg-opacity));
}

.bg-grey200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-grey50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-inputBgGray {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-nBackgroundSecondary {
  --tw-bg-opacity: 1;
  background-color: rgb(247 248 248 / var(--tw-bg-opacity));
}

.bg-newPrimary100 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 248 251 / var(--tw-bg-opacity));
}

.bg-newPrimary200 {
  --tw-bg-opacity: 1;
  background-color: rgb(197 231 230 / var(--tw-bg-opacity));
}

.bg-newPrimary50 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 252 251 / var(--tw-bg-opacity));
}

.bg-newPrimary700 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 160 155 / var(--tw-bg-opacity));
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 236 220 / var(--tw-bg-opacity));
}

.bg-orange100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 236 220 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(77 170 169 / var(--tw-bg-opacity));
}

.bg-primary-100 {
  background-color: var(--primary-100);
}

.bg-primary-50 {
  background-color: var(--primary-50);
}

.bg-primary-500 {
  background-color: var(--primary-500);
}

.bg-primary-600 {
  background-color: var(--primary-600);
}

.bg-primary-700 {
  background-color: var(--primary-700);
}

.bg-primary500 {
  --tw-bg-opacity: 1;
  background-color: rgb(77 170 169 / var(--tw-bg-opacity));
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(172 148 250 / var(--tw-bg-opacity));
}

.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 255 / var(--tw-bg-opacity));
}

.bg-purple100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 235 254 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 232 232 / var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 213 213 / var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 242 / var(--tw-bg-opacity));
}

.bg-red110 {
  --tw-bg-opacity: 1;
  background-color: rgb(218 76 82 / var(--tw-bg-opacity));
}

.bg-red500 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 82 82 / var(--tw-bg-opacity));
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 148 162 / var(--tw-bg-opacity));
}

.bg-teal-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 116 129 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: transparent;
}

.bg-violet-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}

.bg-violet-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(196 181 253 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 178 / var(--tw-bg-opacity));
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 233 106 / var(--tw-bg-opacity));
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 253 234 / var(--tw-bg-opacity));
}

.\!bg-opacity-0 {
  --tw-bg-opacity: 0 !important;
}

.\!bg-opacity-40 {
  --tw-bg-opacity: 0.4 !important;
}

.\!bg-opacity-5 {
  --tw-bg-opacity: 0.05 !important;
}

.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}

.bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.fill-blue-600 {
  fill: #1C64F2;
}

.fill-primary-200 {
  fill: var(--primary-200);
}

.fill-primary-600 {
  fill: var(--primary-600);
}

.stroke-current {
  stroke: currentColor;
}

.object-contain {
  -o-object-fit: contain;
     object-fit: contain;
}

.\!p-0 {
  padding: 0px !important;
}

.\!p-1 {
  padding: 0.25rem !important;
}

.\!p-2 {
  padding: 0.5rem !important;
}

.\!p-4 {
  padding: 1rem !important;
}

.\!p-6 {
  padding: 1.5rem !important;
}

.p-0 {
  padding: 0px;
}

.p-1 {
  padding: 0.25rem;
}

.p-1\.5 {
  padding: 10px;
}

.p-10 {
  padding: 2.5rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-2\.5 {
  padding: 0.625rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[1\.5rem\] {
  padding: 1.5rem;
}

.p-\[14px\] {
  padding: 14px;
}

.p-\[16px\] {
  padding: 16px;
}

.p-\[20px\] {
  padding: 20px;
}

.p-\[24px\] {
  padding: 24px;
}

.p-\[6px\] {
  padding: 6px;
}

.\!px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.\!px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.\!px-2\.5 {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}

.\!px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.\!px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.\!px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.\!py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.\!py-1\.5 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.\!py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.\!py-28 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.\!py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.\!py-3\.5 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.\!py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.\!py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.\!py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.px-\[15px\] {
  padding-left: 15px;
  padding-right: 15px;
}

.px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}

.px-\[24px\] {
  padding-left: 24px;
  padding-right: 24px;
}

.px-\[40px\] {
  padding-left: 40px;
  padding-right: 40px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py-0\.5 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-1\.5 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[16px\] {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-\[20px\] {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-\[21px\] {
  padding-top: 21px;
  padding-bottom: 21px;
}

.py-\[24px\] {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-\[28px\] {
  padding-top: 28px;
  padding-bottom: 28px;
}

.py-\[32px\] {
  padding-top: 32px;
  padding-bottom: 32px;
}

.py-\[67px\] {
  padding-top: 67px;
  padding-bottom: 67px;
}

.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.\!pb-6 {
  padding-bottom: 1.5rem !important;
}

.\!pt-3 {
  padding-top: 0.75rem !important;
}

.\!pt-5 {
  padding-top: 1.25rem !important;
}

.\!pt-6 {
  padding-top: 1.5rem !important;
}

.\!pt-8 {
  padding-top: 2rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-40 {
  padding-left: 10rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-0 {
  padding-right: 0px;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-\[28px\] {
  padding-right: 28px;
}

.pt-0 {
  padding-top: 0px;
}

.pt-0\.5 {
  padding-top: 2px;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-2\.5 {
  padding-top: 0.625rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-40 {
  padding-top: 10rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-\[40px\] {
  padding-top: 40px;
}

.pt-\[69px\] {
  padding-top: 69px;
}

.\!text-left {
  text-align: left !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.\!text-right {
  text-align: right !important;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.\!font-inter {
  font-family: Inter, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif !important;
}

.\!font-sansPop {
  font-family: Poppins, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif !important;
}

.font-\[\'Inter\'\] {
  font-family: 'Inter';
}

.font-inter {
  font-family: Inter, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
}

.font-sans {
  font-family: var(--font-sans), -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
}

.\!text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.\!text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.\!text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-\[18px\] {
  line-height: 18px;
}

.leading-\[21px\] {
  line-height: 21px;
}

.leading-\[30px\] {
  line-height: 30px;
}

.leading-\[42px\] {
  line-height: 42px;
}

.leading-\[45px\] {
  line-height: 45px;
}

.leading-\[72px\] {
  line-height: 72px;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.tracking-\[-0\.01em\] {
  letter-spacing: -0.01em;
}

.\!text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(4 6 7 / var(--tw-text-opacity)) !important;
}

.\!text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}

.\!text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}

.\!text-gray-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(31 42 55 / var(--tw-text-opacity)) !important;
}

.\!text-gray-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(17 25 40 / var(--tw-text-opacity)) !important;
}

.\!text-grey500 {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}

.\!text-inherit {
  color: inherit !important;
}

.\!text-newPrimary700 {
  --tw-text-opacity: 1 !important;
  color: rgb(5 160 155 / var(--tw-text-opacity)) !important;
}

.\!text-primary {
  --tw-text-opacity: 1 !important;
  color: rgb(77 170 169 / var(--tw-text-opacity)) !important;
}

.\!text-primary-600 {
  color: var(--primary-600) !important;
}

.\!text-primary-700 {
  color: var(--primary-700) !important;
}

.\!text-primary110 {
  --tw-text-opacity: 1 !important;
  color: rgb(69 153 152 / var(--tw-text-opacity)) !important;
}

.\!text-primary700 {
  --tw-text-opacity: 1 !important;
  color: rgb(42 189 188 / var(--tw-text-opacity)) !important;
}

.\!text-red-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(200 30 30 / var(--tw-text-opacity)) !important;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-\[\#03543F\] {
  --tw-text-opacity: 1;
  color: rgb(3 84 63 / var(--tw-text-opacity));
}

.text-\[\#111928\] {
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
}

.text-\[\#1E429F\] {
  --tw-text-opacity: 1;
  color: rgb(30 66 159 / var(--tw-text-opacity));
}

.text-\[\#377DFF\] {
  --tw-text-opacity: 1;
  color: rgb(55 125 255 / var(--tw-text-opacity));
}

.text-\[\#42389D\] {
  --tw-text-opacity: 1;
  color: rgb(66 56 157 / var(--tw-text-opacity));
}

.text-\[\#5521B5\] {
  --tw-text-opacity: 1;
  color: rgb(85 33 181 / var(--tw-text-opacity));
}

.text-\[\#59D556\] {
  --tw-text-opacity: 1;
  color: rgb(89 213 86 / var(--tw-text-opacity));
}

.text-\[\#723B13\] {
  --tw-text-opacity: 1;
  color: rgb(114 59 19 / var(--tw-text-opacity));
}

.text-\[\#9B1C1C\] {
  --tw-text-opacity: 1;
  color: rgb(155 28 28 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(4 6 7 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(28 100 242 / var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(26 86 219 / var(--tw-text-opacity));
}

.text-brand-600 {
  color: var(--brand-600);
}

.text-contentSecondary {
  --tw-text-opacity: 1;
  color: rgb(62 75 91 / var(--tw-text-opacity));
}

.text-cyan-300 {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity));
}

.text-emerald-900 {
  --tw-text-opacity: 1;
  color: rgb(6 78 59 / var(--tw-text-opacity));
}

.text-error-500 {
  --tw-text-opacity: 1;
  color: rgb(240 68 56 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 42 55 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(14 159 110 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(5 122 85 / var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(4 108 78 / var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(3 84 63 / var(--tw-text-opacity));
}

.text-green800 {
  --tw-text-opacity: 1;
  color: rgb(3 84 63 / var(--tw-text-opacity));
}

.text-grey300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-grey400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-grey500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-grey900 {
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
}

.text-inherit {
  color: inherit;
}

.text-inputLabelGray {
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
}

.text-inputTextGray {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-newPrimary600 {
  --tw-text-opacity: 1;
  color: rgb(44 178 173 / var(--tw-text-opacity));
}

.text-newPrimary700 {
  --tw-text-opacity: 1;
  color: rgb(5 160 155 / var(--tw-text-opacity));
}

.text-newPrimary800 {
  --tw-text-opacity: 1;
  color: rgb(31 110 108 / var(--tw-text-opacity));
}

.text-orange-900 {
  --tw-text-opacity: 1;
  color: rgb(119 29 29 / var(--tw-text-opacity));
}

.text-orange800 {
  --tw-text-opacity: 1;
  color: rgb(138 44 13 / var(--tw-text-opacity));
}

.text-primary-600 {
  color: var(--primary-600);
}

.text-primary-700 {
  color: var(--primary-700);
}

.text-primary-900 {
  color: var(--primary-900);
}

.text-purple800 {
  --tw-text-opacity: 1;
  color: rgb(85 33 181 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(249 128 128 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(240 82 82 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(224 36 36 / var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(200 30 30 / var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(155 28 28 / var(--tw-text-opacity));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(119 29 29 / var(--tw-text-opacity));
}

.text-red500 {
  --tw-text-opacity: 1;
  color: rgb(240 82 82 / var(--tw-text-opacity));
}

.text-success-500 {
  --tw-text-opacity: 1;
  color: rgb(18 183 106 / var(--tw-text-opacity));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(6 148 162 / var(--tw-text-opacity));
}

.text-transparent {
  color: transparent;
}

.text-violet-800 {
  --tw-text-opacity: 1;
  color: rgb(91 33 182 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(227 160 8 / var(--tw-text-opacity));
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgb(142 75 16 / var(--tw-text-opacity));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(114 59 19 / var(--tw-text-opacity));
}

.text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgb(99 49 18 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.\!opacity-100 {
  opacity: 1 !important;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-50 {
  opacity: 0.5;
}

.\!shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-card {
  --tw-shadow: 2px 2px 10px rgba(0, 0, 0, .08);
  --tw-shadow-colored: 2px 2px 10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.05), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);
  --tw-shadow-colored: 0px 2px 4px -2px var(--tw-shadow-color), 0px 4px 6px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-modal {
  --tw-shadow: 0px 25px 65px 0px rgba(15, 24, 33, 0.29);
  --tw-shadow-colored: 0px 25px 65px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-grey20 {
  --tw-shadow-color: #F0F0F1;
  --tw-shadow: var(--tw-shadow-colored);
}

.\!outline-none {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.outline {
  outline-style: solid;
}

.\!outline-0 {
  outline-width: 0px !important;
}

.\!outline-primary-200 {
  outline-color: var(--primary-200) !important;
}

.\!outline-primary-600 {
  outline-color: var(--primary-600) !important;
}

.\!ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-\[\#E6E6E6\] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(230 230 230 / var(--tw-ring-opacity));
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(4 6 7 / var(--tw-ring-opacity));
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

.ring-primary-600 {
  --tw-ring-color: var(--primary-600);
}

.ring-red {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(242 84 91 / var(--tw-ring-opacity));
}

.ring-violet-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(167 139 250 / var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: 0.05;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-\[\.4s\] {
  transition-duration: .4s;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-appearance {
  -webkit-appearance: none !important;
}

.before\:absolute::before {
  content: var(--tw-content);
  position: absolute;
}

.before\:bottom-\[4px\]::before {
  content: var(--tw-content);
  bottom: 4px;
}

.before\:left-\[4px\]::before {
  content: var(--tw-content);
  left: 4px;
}

.before\:h-\[20px\]::before {
  content: var(--tw-content);
  height: 20px;
}

.before\:w-\[23px\]::before {
  content: var(--tw-content);
  width: 23px;
}

.before\:rounded-\[20px\]::before {
  content: var(--tw-content);
  border-radius: 20px;
}

.before\:bg-white::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.before\:transition::before {
  content: var(--tw-content);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.before\:duration-\[\.4s\]::before {
  content: var(--tw-content);
  transition-duration: .4s;
}

.before\:content-\[\'\'\]::before {
  --tw-content: '';
  content: var(--tw-content);
}

.after\:absolute::after {
  content: var(--tw-content);
  position: absolute;
}

.after\:left-\[2px\]::after {
  content: var(--tw-content);
  left: 2px;
}

.after\:top-\[2px\]::after {
  content: var(--tw-content);
  top: 2px;
}

.after\:h-4::after {
  content: var(--tw-content);
  height: 1rem;
}

.after\:h-5::after {
  content: var(--tw-content);
  height: 1.25rem;
}

.after\:h-6::after {
  content: var(--tw-content);
  height: 1.5rem;
}

.after\:w-4::after {
  content: var(--tw-content);
  width: 1rem;
}

.after\:w-5::after {
  content: var(--tw-content);
  width: 1.25rem;
}

.after\:w-6::after {
  content: var(--tw-content);
  width: 1.5rem;
}

.after\:rounded-full::after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:border::after {
  content: var(--tw-content);
  border-width: 1px;
}

.after\:border-gray-300::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.after\:bg-white::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.after\:transition-all::after {
  content: var(--tw-content);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.after\:content-\[\'\'\]::after {
  --tw-content: '';
  content: var(--tw-content);
}

.first\:rounded-bl-lg:first-child {
  border-bottom-left-radius: 0.5rem;
}

.first\:rounded-tl-lg:first-child {
  border-top-left-radius: 0.5rem;
}

.last\:rounded-br-lg:last-child {
  border-bottom-right-radius: 0.5rem;
}

.last\:rounded-tr-lg:last-child {
  border-top-right-radius: 0.5rem;
}

.last\:border-0:last-child {
  border-width: 0px;
}

.last\:border-r-0:last-child {
  border-right-width: 0px;
}

.first-of-type\:\!border-l-0:first-of-type {
  border-left-width: 0px !important;
}

.first-of-type\:border-l-0:first-of-type {
  border-left-width: 0px;
}

.last-of-type\:\!border-r-0:last-of-type {
  border-right-width: 0px !important;
}

.last-of-type\:border-b-0:last-of-type {
  border-bottom-width: 0px;
}

.last-of-type\:border-r-0:last-of-type {
  border-right-width: 0px;
}

.checked\:\!border-4:checked {
  border-width: 4px !important;
}

.checked\:\!border-\[var\(--primary-700\)\]:checked {
  border-color: var(--primary-700) !important;
}

.checked\:\!border-primary:checked {
  --tw-border-opacity: 1 !important;
  border-color: rgb(77 170 169 / var(--tw-border-opacity)) !important;
}

.hover\:scale-95:hover {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:cursor-not-allowed:hover {
  cursor: not-allowed;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:\!border-newPrimary700:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(5 160 155 / var(--tw-border-opacity)) !important;
}

.hover\:\!border-primary-600:hover {
  border-color: var(--primary-600) !important;
}

.hover\:\!border-primary-700:hover {
  border-color: var(--primary-700) !important;
}

.hover\:\!bg-transparent:hover {
  background-color: transparent !important;
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-primary-100:hover {
  background-color: var(--primary-100);
}

.hover\:bg-primary-200:hover {
  background-color: var(--primary-200);
}

.hover\:bg-primary20:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 238 238 / var(--tw-bg-opacity));
}

.hover\:bg-transparent:hover {
  background-color: transparent;
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-gradient-to-l:hover {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}

.hover\:\!text-primary-700:hover {
  color: var(--primary-700) !important;
}

.hover\:\!text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
}

.hover\:text-primary-500:hover {
  color: var(--primary-500);
}

.hover\:text-primary-600:hover {
  color: var(--primary-600);
}

.hover\:opacity-70:hover {
  opacity: 0.7;
}

.hover\:opacity-90:hover {
  opacity: 0.9;
}

.focus\:\!border-newPrimary700:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgb(5 160 155 / var(--tw-border-opacity)) !important;
}

.focus\:\!border-primary-600:focus {
  border-color: var(--primary-600) !important;
}

.focus\:\!border-primary-700:focus {
  border-color: var(--primary-700) !important;
}

.focus\:\!bg-transparent:focus {
  background-color: transparent !important;
}

.focus\:bg-transparent:focus {
  background-color: transparent;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:\!outline:focus {
  outline-style: solid !important;
}

.focus\:\!outline-1:focus {
  outline-width: 1px !important;
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:\!ring-red:focus {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(242 84 91 / var(--tw-ring-opacity)) !important;
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(104 117 245 / var(--tw-ring-opacity));
}

.focus\:ring-newPrimary700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(5 160 155 / var(--tw-ring-opacity));
}

.focus\:ring-primary:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(77 170 169 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.active\:scale-90:active {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.active\:scale-95:active {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.active\:bg-gray-200:active {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.active\:\!text-primary-600:active {
  color: var(--primary-600) !important;
}

.active\:text-primary-600:active {
  color: var(--primary-600);
}

.active\:opacity-30:active {
  opacity: 0.3;
}

.active\:opacity-50:active {
  opacity: 0.5;
}

.active\:opacity-80:active {
  opacity: 0.8;
}

.active\:opacity-90:active {
  opacity: 0.9;
}

.disabled\:\!border-gray-300:disabled {
  --tw-border-opacity: 1 !important;
  border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:block {
  display: block;
}

.group:hover .group-hover\:cursor-pointer {
  cursor: pointer;
}

.group:hover .group-hover\:\!text-yellow-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(252 233 106 / var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-primary110 {
  --tw-text-opacity: 1;
  color: rgb(69 153 152 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(6 148 162 / var(--tw-text-opacity));
}

.peer:checked ~ .peer-checked\:bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 42 55 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 108 78 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:bg-newPrimary700 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 160 155 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:bg-primary-700 {
  background-color: var(--primary-700);
}

.peer:checked ~ .peer-checked\:bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(200 30 30 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:translate-x-\[19px\]::before {
  content: var(--tw-content);
  --tw-translate-x: 19px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:after\:translate-x-full::after {
  content: var(--tw-content);
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:after\:border-white::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.peer:focus ~ .peer-focus\:outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.peer:focus ~ .peer-focus\:ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.peer:focus ~ .peer-focus\:ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

.peer:focus ~ .peer-focus\:ring-green-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(132 225 188 / var(--tw-ring-opacity));
}

.peer:focus ~ .peer-focus\:ring-primary-300 {
  --tw-ring-color: var(--primary-300);
}

.peer:focus ~ .peer-focus\:ring-red-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 180 180 / var(--tw-ring-opacity));
}

@media (min-width: 640px) {
  .sm\:\!w-full {
    width: 100% !important;
  }

  .sm\:w-\[343px\] {
    width: 343px;
  }

  .sm\:w-\[90vw\] {
    width: 90vw;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-xs {
    max-width: 20rem;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .sm\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

@media (min-width: 768px) {
  .md\:inset-0 {
    inset: 0px;
  }

  .md\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .md\:mb-3 {
    margin-bottom: 0.75rem;
  }

  .md\:mt-5 {
    margin-top: 1.25rem;
  }

  .md\:\!block {
    display: block !important;
  }

  .md\:\!h-fit {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }

  .md\:\!h-screen {
    height: 100vh !important;
  }

  .md\:\!max-h-screen {
    max-height: 100vh !important;
  }

  .md\:max-h-\[90vh\] {
    max-height: 90vh;
  }

  .md\:\!w-\[360px\] {
    width: 360px !important;
  }

  .md\:\!w-\[362px\] {
    width: 362px !important;
  }

  .md\:\!w-\[412px\] {
    width: 412px !important;
  }

  .md\:\!w-\[570px\] {
    width: 570px !important;
  }

  .md\:\!w-fit {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .md\:w-\[412px\] {
    width: 412px;
  }

  .md\:w-\[calc\(100vw-24px\)\] {
    width: calc(100vw - 24px);
  }

  .md\:w-fit {
    width: -moz-fit-content;
    width: fit-content;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:w-screen {
    width: 100vw;
  }

  .md\:max-w-\[234px\] {
    max-width: 234px;
  }

  .md\:\!flex-col {
    flex-direction: column !important;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:\!rounded-lg {
    border-radius: 0.5rem !important;
  }

  .md\:\!rounded-md {
    border-radius: 0.375rem !important;
  }

  .md\:\!rounded-none {
    border-radius: 0px !important;
  }

  .md\:rounded-lg {
    border-radius: 0.5rem;
  }

  .md\:\!p-4 {
    padding: 1rem !important;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:\!px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .md\:\!px-\[40px\] {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .md\:\!py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .md\:\!py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:px-\[10\%\] {
    padding-left: 10%;
    padding-right: 10%;
  }

  .md\:px-\[16px\] {
    padding-left: 16px;
    padding-right: 16px;
  }

  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .md\:\!pb-4 {
    padding-bottom: 1rem !important;
  }

  .md\:pt-\[10\%\] {
    padding-top: 10%;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .lg\:\!whitespace-normal {
    white-space: normal !important;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1280px) {
  .xl\:\!rounded-none {
    border-radius: 0px !important;
  }
}

.rtl\:text-right:where([dir="rtl"], [dir="rtl"] *) {
  text-align: right;
}

@media (prefers-color-scheme: dark) {
  .dark\:text-cyan-100 {
    --tw-text-opacity: 1;
    color: rgb(207 250 254 / var(--tw-text-opacity));
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.\[\&\>\*\]\:w-full>* {
  width: 100%;
}

.\[\&\>div\>div\]\:flex>div>div {
  display: flex;
}

.\[\&\>div\>div\]\:justify-between>div>div {
  justify-content: space-between;
}
